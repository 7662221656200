// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Print
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-no-qualifying-type, selector-max-type */

/* These styles are intended to be global */

a[href^="//"] {
    &::after {
        content: " (" attr(href) ")";
        font-size: 80%;
    }
}

.__noprint {
    & {
        display: none !important;
    }
}

.nf-form-cont {
    & {
        display: none;
    }
}
